import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"

// - Data
import useMetaData from "../hook/metadata-data"

const Metadata = ({ title, description, image }) => {
  const {
    titletemplate,
    defaulttitle,
    defaultdescription,
    defaultimage,
    siteurl,
    keywords,
  } = useMetaData()

  const { pathname } = useLocation()
  const siteName = " | CHIA CHING CHOU - UIUX Designer"
  const themeColor = "#141414"
  const author = "CHIA CHING CHOU"
  const imageHeight = "600"
  const imageWidth = "1200"

  const seo = {
    title: title ? `${title}` : defaulttitle.text,
    description: description || defaultdescription.text,
    // image: `${siteurl.text}${image || defaultimage.url}`,
    image: `${defaultimage.url}`,
    image_width: `${imageWidth}`,
    image_height: `${imageHeight}`,
    url: `${siteurl.text}${pathname}`,
    author: `${author}`,
    theme: `${themeColor}`,
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate={titletemplate.text}
      htmlAttributes={{ lang: "en" }}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="author" content={seo.author} />
      <meta name="theme-color" content={seo.theme} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:image:width" content={seo.image_width} />
      <meta property="og:image:height" content={seo.image_height} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
    </Helmet>
  )

  // pug`
  //   Helmet(title=seo.title titletemplate=titletemplate.text htmlAttributes={lang: 'en',})
  //     meta(name="description" content=seo.description)
  //     meta(name="keywords", content=keywords.text)
  //     meta(name="image" content=seo.image)
  //     meta(name="theme-color" content=seo.theme)

  //     meta(property="og:site_name", content=seo.title)
  //     meta(property="og:description", content=seo.description)
  //     meta(property="og:title", content=seo.title)
  //     meta(property="og:image", content=seo.image)
  //     meta(property="og:url" content=seo.url)
  //     meta(property="og:type", content="website")

  // `
}

export default Metadata

Metadata.PropTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
}

Metadata.defaultProps = {
  title: null,
  description: null,
  image: null,
}
