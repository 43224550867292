import { useStaticQuery, graphql } from "gatsby"

const useMetaData = () => {
  const { MetaData } = useStaticQuery(
    graphql`
      query {
        MetaData: prismicMetadata {
          data {
            defaulttitle {
              text
            }
            siteurl {
              text
            }
            titletemplate {
              text
            }
            defaultimage {
              url
            }
            defaultdescription {
              text
            }
            keywords {
              text
            }
          }
        }
      }
    `
  )
  return MetaData.data
}

export default useMetaData
